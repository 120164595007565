import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/permisson'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import request from "@/utils/request";



Vue.prototype.$request = request
Vue.prototype.$baseUrl = process.env.VUE_APP_BASEURL

// 导入样式初始化
import 'normalize.css'
import './style.css'
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
