<template>
    <div class="header-content w-100">
        <div class="main-content ">
            <div class="d-flex d-al-c h-100">
                <div class="login d-flex  d-al-c d-ju-c">
                    <img width="150px" src="@/assets/logo.png" alt="" srcset="">
                </div>
                <div class="tabbar-menu-size d-flex flex-d-c d-al-c d-ju-sb" v-for="(item, index) in tabbarList"
                    :key="index" @click="changeTab(item.path, index)">
                    <div class="tabber-title" :class="{ activeTitle: sum === index }">{{ item.name }}</div>
                    <div class="tabber-line" :class="{ active: sum === index }"></div>
                </div>
                <div class="right-btn-box d-flex  d-al-c d-ju-c">
                    <div class="btn-size d-flex  d-al-c d-ju-c" :class="{ activeBtn: sum === -1 }"
                        @click="changeTab('activate', -1)">立即开通</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabbarList: [
                { name: '首页', path: '/', text: 'home' },
                { name: '核心技术', path: 'technology', text: 'technology' },
                { name: '应用场景', path: 'scene', text: 'scene' },
                { name: '定价', path: 'price', text: 'price' },
                { name: '关于我们', path: 'about', text: 'about' },
                { name: '联系我们', path: 'contact', text: 'contact' }
            ],

        }
    },
    
computed:{
    sum(){
      return this.$store.state.tabIndex
    },
 },
    created() {

    },
    mounted() {
        let index = this.tabbarList.findIndex(item => item.text === this.$route.name)
        this.$store.commit('setTabIndex',index)
    },
    methods: {
        changeTab(path, index) {
            this.$store.commit('setTabIndex',index)
            this.$router.push(path)
        },

    }
}
</script>
<style scoped>
.header-content {
    width: 100%;
    height: 66px;
    background: #FFFFFF;
    box-shadow: 2px 0px 6px 0px #E8DDF6;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}

.h-100 {
    height: 100px;
}

.login {
    width: 200px;
    margin-right: 100px;
    height: 100%;
}

.tabbar-menu-size {
    width: 80px;
    height: 100%;
    cursor: pointer;
    margin: 0 25px;
    color: #666;
}

.right-btn-box {
    width: 200px;
    height: 100%;
}

.btn-size {
    width: 100px;
    height: 40px;
    border: 1px solid #4b81fc;
    color: #4b81fc;
    border-radius: 5px;
    cursor: pointer;
}

.tabber-line {
    width: 30px;
    height: 3px;
    background: #ffffff;
}

.active {
    background: #4b81fc;
}

.activeTitle {
    color: #4b81fc;
    font-weight: 600;
}

.tabber-title {
    margin-top: 25px;
}

.activeBtn {
    background: #4b81fc;
    color: #ffffff;
}
</style>