import router  from "./index";
// 配置导航守卫
router.beforeEach((to,from,next)=>{
    // 判断进入这个路由界面的时候是否需要登录
    if(to.meta.isLogin){//需要登录
      // 判断是否登录
      next("/login")
    }else{//不需要登录
      next()
    }
  })

  router.afterEach((to,from,next)=>{
    window.scrollTo(0,0)
  })
